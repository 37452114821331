import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import SbEditable from "storyblok-react"
import DynamicComponent from "../components/DynamicComponent"
import useStoryblok from "../utils/storyblok"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Policy from "../components/privacy-policy/Policy"

const PrivacyPolicy = ({ data, location }) => {
  return (
    <Layout>
      <Seo title="Privacy policy" pageName="privacy-policy" />
      <Policy />
    </Layout>
  )
}

export default PrivacyPolicy
